@import './../../../theme/theme';

.VegetationChangeContainer,
.embed_VegetationChangeContainer {
  position: fixed;
  left: 10px;
  right: auto;
  margin: 0 auto;
  width: fit-content;
  height: 36px;
  border-radius: 13px;
  background-color: $backgroundColor;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  z-index: 10;
  max-width: 100%;
  box-shadow: 0px 3px 6px #00000029;
}
.VegetationChangeContainer {
  top: 100px;
}

.embed_VegetationChangeContainer {
  top: 30px;
}

.options {
  height: 100%;
  border: 1px solid $dividerColor;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-weight: 700;
  cursor: pointer;
  width: 50px;
}

.options svg {
  height: 18px;
  padding-right: 4px;
}
.options > svg > path {
  fill: $primaryFontColor;
}
.options p {
  display: none;
  color: $primaryFontColor;
}

.options p sup {
  padding-left: 1px;
  font-size: 8px;
  color: $primaryFontColor;
}

.dropdownContainer,
.embed_dropdownContainer {
  position: fixed;
  top: 140px;
  left: 0;
  right: 0;
  padding: 0 10px;
  margin: 0 auto;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  overflow: hidden;
  height: 50px;
  z-index: 3;
}

.embed_dropdownContainer {
  top: 75px;
}

.beforeYear {
  z-index: 10;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  > div {
    padding-right: 20px;
  }
}

.afterYear {
  z-index: 10;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  > div {
    padding-left: 20px;
  }
}

.sourceIcon {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 8px;
  background: $backgroundColor;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 9px;
  line-height: 16px;
  cursor: pointer;
  svg {
    width: 20px;
  }
}

:export {
  primaryColor: $primaryColor;
}

@include smTabletView {
  .VegetationChangeContainer {
    top: 100px;
  }
  .embed_VegetationChangeContainer {
    top: 30px;
  }

  .dropdownContainer {
    top: 145px;
  }
  .embed_dropdownContainer {
    top: 75px;
  }
}

@include mdTabletView {
  .VegetationChangeContainer {
    left: 400px;
    top: 110px;
  }
  .embed_VegetationChangeContainer {
    top: 30px;
    left: 400px;

    &.no-project-details {
      left: 24px;
    }
  }

  .dropdownContainer {
    padding: 0 20px 0 400px;
    top: 160px;
  }
  .embed_dropdownContainer {
    padding: 0 20px 0 400px;
    top: 80px;

    &.no-project-details {
      padding-left: 24px;
    }
  }
}

@include lgLaptopView {
  .VegetationChangeContainer,
  .embed_VegetationChangeContainer {
    // margin-left: auto;
    right: auto;
  }
}

@include xlDesktopView {
  .options {
    width: 200px;
    &.compact {
      width: 150px;
    }
    p {
      display: block;
    }
  }
}
